@import 'variables';
* {
    box-sizing: border-box;
}

html, body {
  margin: 0;
  font-size: 16px;
  width: 100%;
  height: 100%;
}

.wrapper {
    width: 100%;
}

.content {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 40px;
    font-family: $main-font;

    @include bp ($tablet) {
        max-width: initial;
        // padding: 0 10px;
        padding: 0;
    }

    // @include bp ($mobile) {
    //     padding: 0;
    //     width: calc(100% - 20px);
    // }
}

h1 {
    color: $white;
    font-size: 40px;
    margin: 0 0 40px 0;
    font-weight: normal;
    font-family: $main-font;
}

h2 {
    color: $dark-grey;
    font-size: 40px;
    margin: 0 0 40px 0;
    font-weight: normal;
    font-family: $main-font;
}

h3 {
    color: $dark-blue;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

h5 {
    color: $white;
    text-align: center;
    text-transform: uppercase;
    font-size: 23px;
    margin: 0 0 10px 0;
    font-weight: bold;
}

p{
    color: $white;
}

ul {
    padding-left: 10px;

    li {
        margin: 0 0 10px 0;
    }
}

hr {
    border: 0;
    height: 1px;
    background: $lighter-grey;
    margin: 40px 0;
}

.no-orphin {
    white-space: nowrap;
    font-size: 14px!important;
 }

@import 'header';
@import 'main-banner';
@import 'main-content';
@import 'quote';
@import 'footer';
