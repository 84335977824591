/**
 * Variables
*/

/* Typography */
/* Breakpoints */
/* Paths */
/* Grid setup */
/* Colour palette */
$black: #000;
$white: #fff;

/* Components */
