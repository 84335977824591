/**
 * Helper mixins
*/

// Clearfix
// http://www.cssmojo.com/latest_new_clearfix_so_far/
// Other clearfix options: https://github.com/stubbornella/oocss/blob/master/oocss/src/components/utils/_clearfix-me.scss
@mixin clearfix {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

// Media queries
// @mixin bp($point) {
//   @if $point == large {
//     @media (min-width: 1280px) { @content; }
//   } @else if $point == small {
//     @media (max-width: 1279px) { @content; }
//   }
// }

$mobile: '767px';
$tablet: '1023px';
@mixin bp ($size) {
  @media (max-width: $size) {
    @content
  }
}

// Text selection
@mixin text-selection($color-selection, $color-selection-bg) {
  ::-moz-selection { // sass-lint:disable-line no-vendor-prefixes
    background: $color-selection-bg;
    color: $color-selection;
    text-shadow: none;
  }

  ::selection {
    background: $color-selection-bg;
    color: $color-selection;
    text-shadow: none;
  }
}

//Placeholders
//Usage:
//@include placeholder;
@mixin placeholder {
  // sass-lint:disable no-vendor-prefixes
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
