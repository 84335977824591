.quote-section {
  @include bp($tablet) {
    padding: 0 20px;
  }
}

.quote {
    text-align: center;
    font-style: italic;
    font-size: 23px;
    color: $dark-blue;
    margin: 0 0 50px 0;


    &__credit {
      font-size: 16px;
      color: $black;
      font-weight: bold;
      @include bp($tablet) {
        display: inline-block;
        margin: 10px 0 0 0;
      }
    }
}
