$dark: #222;
$p: deeppink;
$lighter-blue: #CDDCE3;
$light-blue: #009eb8;
$dark-blue: #3a398c;
$dark-grey: #16262e;
$lighter-grey: #999;
$light-grey: #F7F7F7;
$evil-grey: #666;

$main-font: 'Open Sans', arial, sans;
