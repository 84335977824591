.main-content-holder {
    margin: 80px 0 0 0;

    @include bp ($mobile) {
        margin: 20px 0 0 0;
    }
}

.main-content {

    display: flex;
    flex-wrap: no-wrap;
    justify-content: space-between;

    @include bp ($tablet) {
        flex-direction: column;
    }
}

.about {
    flex-basis: 320px;
    min-width: 320px;
    background: $dark-grey;
    color: $white;
    padding: 40px 40px 0 40px;
    font-size: 14px;

    @include bp ($tablet) {
        padding: 20px;
    }

    li {
        font-size: 14px;

        @include bp ($mobile) {
        }
    }

}

.whatwedo {

    margin: 0 0 0 40px;

    @include bp ($tablet) {
        margin: 40px 0 0 0;
        padding: 0 20px;
    }

    &__item-heading {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__item-icon {
        width: 50px;
        margin: 7px 20px 0 0;
    }

    &__item-holder {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include bp ($tablet) {
            flex-direction: column;
        }
    }

    &__item {
        flex-basis: 48%;
    }

    &__item-content {
        ul{
            margin-left: 65px;
            li {
                font-size: 14px;
                color: $lighter-grey;
                margin: 0;
            }
        }
    }
}
