.header {
    height: 150px;
    background: $dark;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include bp($tablet) {
        height: 120px;
    }

    &__logo-holder{
      padding: 0 20px;
    }

    &__logo {
        height: 41px;

        @include bp($tablet) {
            height: 50px;
        }
    }
}
