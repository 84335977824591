.main-banner {
    max-height: 550px;
    background: url('/images/banner.jpg');
    background-position-x: center;

    &__content {
        height: 550px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        position: relative;

        // @include bp ($tablet) {
        //     background-position-x: center;
        // }

        @include bp ($tablet) {
            background: none;
            flex-direction: column;
            justify-content: flex-start;
            height: initial;
        }
    }

    &__copy {
        position: absolute;
        top: 17%;
        text-transform: uppercase;
        font-size: 36px;
        line-height: 38px;
        font-weight: 300;
        width: 344px;

        &--highlight {
            color: $dark-blue;
        }

        @include bp ($tablet) {
            display: none;
        }
    }

    &__box-holder {
      display: flex;

      @include bp ($tablet) {
        flex-direction: column;
      }
    }

    &__box {
        min-height: 155px;
        padding: 20px 80px;
        min-width: 352px;
        width: calc(50% - 10px);
        display: flex;
        justify-content: center;
        align-items: center;

        // @include bp ($tablet) {
          //     min-width: initial;
          // }

          @include bp ($tablet) {
            padding: 20px;
            width: 100%;
            min-width: initial;
        }

        h5 {
            margin: 0;
        }

        p{
            margin: 8px 0;
        }

        &-content {
            text-align: center;
        }

        &--purpose {
            background: $light-blue;
            margin: 0 10px 0 0;
            @include bp ($tablet){
              margin: 0;
            }
          }

          &--code {
            background: $dark-blue;
            margin: 0 0 0 10px;
            @include bp ($tablet){
              margin: 0;
            }
        }
    }

}
