.footer {

    background: $light-grey;
    padding: 40px 0;

    @include bp($tablet){
      padding: 40px 20px;
    }

    h2 {
        font-size: 32px;
        color: $dark-grey;

    }

    &__link {
        padding: 0 0 0 30px;
        display: block;

        &--telephone {
            color: $dark-grey;
            text-decoration: none;
            position: relative;
            &:before {
                content: '';
                width: 21px;
                height: 16px;
                display: block;
                position: absolute;
                background: url('../images/address-icon.png') no-repeat;
                top: 1px;
                left: 4px;
            }
        }

        &--email {
            color: $dark-blue;
            &:active,  &:visited {
                color: $dark-blue;
            }
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;

        @include bp($mobile) {
            flex-direction: column;
        }
    }

    &__social-link {
        color: $dark-grey;
        text-decoration: none;
        transition: all 0.4s;

        // hide buttons for now
        opacity: 0;
        pointer-events: none;

        &:hover {
            color: $dark-blue;
        }
    }

    &__social-divider {
        padding: 0 10px;
        color: $lighter-grey;

        // hide buttons for now
        opacity: 0;

    }

    &__copyright {
        color: $dark-grey;
    }

    &__copyright-link {
        color: $dark-grey;
        text-decoration: none;
        transition: all 0.4s;
        &:hover {
            color: $dark-blue;
        }
    }

}
